// extracted by mini-css-extract-plugin
export var body = "ingredientDetail-module--body--82b54";
export var ewgLine = "ingredientDetail-module--ewgLine--3b5d1";
export var explainText = "ingredientDetail-module--explainText--f1dc2";
export var grayStyle = "ingredientDetail-module--grayStyle--67340";
export var headerContainer = "ingredientDetail-module--headerContainer--971c0";
export var imageStyle = "ingredientDetail-module--imageStyle--03d6b";
export var purposeLine = "ingredientDetail-module--purposeLine--712be";
export var remarksText = "ingredientDetail-module--remarksText--bc344";
export var textButton = "ingredientDetail-module--textButton--f3957";
export var titleLine = "ingredientDetail-module--titleLine--447bf";
export var titleStyle = "ingredientDetail-module--titleStyle--91439";
export var typeLine = "ingredientDetail-module--typeLine--42a5f";