import React from 'react';
import { images, ingredientExplainText, ingredientExplainTitle } from '../../data';
import * as styles from '../../styles/ingredientDetail/ingredientExplainItem.module.css';

const IngredientExplainItem = (props: { value: string; imageStyle?: any }) => {
  const { value, imageStyle } = props;
  const explainText = ingredientExplainText[value].split('\n');
  return (
    <div className={styles.row}>
      <img
        className={imageStyle ? imageStyle : styles.defaultImageStyle}
        // style={}
        src={images[value]}
      />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <span className={styles.explainTitle}>{ingredientExplainTitle[value]}</span>
        <span className={styles.explainText}>
          {explainText[0]}
          {explainText[1] && (
            <span style={{ fontSize: 10 }}>
              {'\n'}
              {explainText[1]}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

export default IngredientExplainItem;
