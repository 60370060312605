import { AxiosRequestConfig } from 'axios';
import { appAxios } from './commom';

export const getKeywordSuggestion = async (keyword: string) => {
  const options: AxiosRequestConfig = {
    url: `/v3/search/suggestion`,
    method: 'get',
    params: {
      keyword,
      page: 0,
      limit: 30,
    },
  };

  try {
    const result = await appAxios.request(options);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * 검색어 결과
 */
export const apiProductKeywordPrediction = async (
  keyword: string,
  page: number,
  limit: number,
  withinKeyword?: string,
  categoryId?: number,
) => {
  const options: AxiosRequestConfig = {
    url: `/v3/search/result`,
    method: 'GET',
    params: {
      keyword,
      page,
      limit,
      withinKeyword,
      categoryId,
    },
  };

  try {
    const result = await appAxios.request(options);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const apiProductDetailV3 = async (id: string | number) => {
  const options: AxiosRequestConfig = {
    url: `/v3/products/${id}`,
    method: 'GET',
  };

  try {
    const result = await appAxios.request(options);

    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const apiGetClinicalTrials = async (page: number, limit: number, productId: number) => {
  const options: AxiosRequestConfig = {
    url: 'v4/clinical-trials',
    params: { page, limit, productId },
    method: 'GET',
  };

  const result = await appAxios.request(options);
  return result.data.data;
};

/***
 * 상품연결정보
 */
export const apiProductUser = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `/v1/members/products/${id}`,
    method: 'GET',
  };

  try {
    const result = await appAxios.request(options);

    return result.data.data;
  } catch (error) {
    throw error;
  }
};

/***
 * 상품별 리뷰 리스트
 */
export const apiReviews = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `/v1/reviews/${id}`,
    method: 'GET',
  };

  try {
    const result = await appAxios.request(options);

    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const apiBestReview = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `/v2/reviews/${id}/best`,
    method: 'GET',
  };

  try {
    const result = await appAxios.request(options);

    return result.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * 네이버 쇼핑
 */
export const getProductNaverShopping = async (productId: number) => {
  const options: AxiosRequestConfig = {
    url: `/v2/search/naver/shopping`,
    method: 'GET',
    params: {
      productId,
    },
  };

  try {
    const result = await appAxios.request(options);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param category 가정용화학제품
 * @param subCategory
 * @param grades 맘가이드 평가 등급: ("S" | "A" | "B" |"'C" | "D" | 'X') array
 * @param order 정렬 순서: 'continued' | 'view' | 'latest' | 'score' | 'random' | 'ranking'. ranking의 경우 categoryId 필수
 * @param keyword
 * @param page
 * @param limit
 * @param issueFilter 단종
 * @param categoryId 카테고리 id값
 * @param brandId 브랜드 id값
 */
export const apiProductSearchV3 = async (
  grades: string[],
  order: string,
  keyword?: string | null,
  page?: number,
  limit?: number,
  issueFilter?: string | null,
  categoryId?: number | null,
  brandId?: number | null,
) => {
  const options: AxiosRequestConfig = {
    url: `/v3/products`,
    method: 'GET',
    params: {
      page: page || 0,
      limit: limit ? limit : 20,
      keyword: !keyword ? '' : keyword,
      grades,
      order: 'ranking', //조회순으로 고정
      issueFilter,
      categoryId,
      brandId,
    },
    // params: {
    //   ...tempParams,
    //   page: page || 0,
    // },
  };
  try {
    const result = await appAxios.request(options);

    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const getIngredient = async (id: number, version: string) => {
  const options: AxiosRequestConfig = {
    url: `/v2/ingredients/${id}/detail`,
    method: 'GET',
    params: {
      version,
    },
  };
  try {
    const result = await appAxios.request(options);

    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const apiIngredientLog = async (version: string, ingredientId: string) => {
  const options: AxiosRequestConfig = {
    url: `/v2/ingredients/log`,
    method: 'POST',
    data: {
      version,
      ingredientId,
    },
  };

  try {
    const result = await appAxios.request(options);
    return result.data.data;
  } catch (error) {
    throw error;
  }
};
