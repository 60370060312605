import React, { useEffect, useState } from 'react';
import { apiIngredientLog, getIngredient } from '../../apis/productApi';
import AppContainerView from '../../components/AppContainerView';
import MobileHeader, { LeftArrow } from '../../components/MobileHeader';
import MobileTemplate from '../../components/MobileTemplate';
import * as styles from '../../styles/ingredientDetail/ingredientDetail.module.css';

import { images, ingredientExplainText, ingredientGradeText } from '../../data';
import IngredientExplainItem from '../../components/ingredientDetail/IngredientExplainItem';
import { CautionCircleIcon } from '../../components/productDetail/IngredientIconHeader';
import { getFilterData } from '../../utils';
import { navigate } from 'gatsby-link';
import { SEO } from 'src/components/seo';

const IngredientDetail = ({ params, location }: any) => {
  const ingredientId = params.id;
  const queryParams = new URLSearchParams(location.search);
  const version = queryParams.get('version') || 'v1';
  const [ingredient, setIngredient] = useState<any>();
  const [ingKorName, setIngKorName] = useState<string>('');

  const code = version === 'v1' ? ingredient?.ewgGrade : ingredient?.ewgCode;
  const gradeIcon = (version === 'v1' ? 'ewgGrade' : 'ewgCode') + code;
  const isAllergic = 'allergic' + ingredient?.allergic;
  const momguideGrade = 'momguideGrade' + ingredient?.momguideGrade;
  const isDSL = 'isDSL' + ingredient?.isDSL;
  const isEPA = 'isEPA' + ingredient?.EPA;
  const echaCMR =
    'echaCMR' + (ingredient?.echaCMR === 'C' || ingredient?.echaCMR === 'CC' || ingredient?.echaCMR === 'CCC' ? 'C' : ingredient?.echaCMR);
  const ewgGrade = 'ewgGrade' + ingredient?.ewgGrade;
  const cosGrade = 'cosGrade' + ingredient?.ewgGrade;
  const isSlsSles = 'isSlsSles' + (ingredient?.isSlsSles ? 'Red' : '');
  const isFragrance = 'isFragrance' + (ingredient?.isFragrance ? 'Red' : '');
  const isAmmonium = 'isAmmonium' + (ingredient?.isAmmonium === 1 ? 'Red' : ingredient?.isAmmonium === 2 ? 'Yellow' : '');
  const isHumid = 'isHumid' + (ingredient?.isHumid ? 'Red' : '');
  const isColor = 'isColor' + (ingredient?.isColor ? 'Red' : '');

  //주의성분
  const isEmptyCaution =
    ingredientExplainText[isDSL] === undefined &&
    ingredientExplainText[isEPA] === undefined &&
    ingredientExplainText[momguideGrade] === undefined &&
    ingredientExplainText[echaCMR] === undefined &&
    ingredientExplainText[isSlsSles] === undefined &&
    ingredientExplainText[isFragrance] === undefined &&
    ingredientExplainText[isAmmonium] === undefined &&
    ingredientExplainText[isHumid] === undefined &&
    ingredientExplainText[isColor] === undefined &&
    !ingredient?.isHarmness;

  //성분독성정보
  const isEmptyToxicity = !(ingredient?.echaBreath || ingredient?.echaSkin || ingredient?.echaGrowth || ingredientExplainText[isAllergic]);

  useEffect(() => {
    const init = async () => {
      const res = await getIngredient(ingredientId, version);
      setIngredient(res);
      setIngKorName((prev) => res.korName);
      apiIngredientLog(version === 'v1' ? 'v1' : 'v2', ingredientId);
    };
    init();
  }, []);

  return (
    <AppContainerView>
      <MobileTemplate>
        <div style={{ flex: 1 }}>
          <div className={styles.headerContainer}>
            <LeftArrow
              style={{ position: 'absolute', left: 20, cursor: 'pointer' }}
              width={15}
              height={15}
              onClick={() => history.back()}
              color={'#898989'}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
              }}
            >
              <span
                style={{
                  color: '#000000',
                  fontSize: 14,
                  fontWeight: 700,
                  textAlign: 'center',
                }}
              >
                {ingredient?.korName}
              </span>

              <span
                style={{
                  color: '#898989',
                  fontSize: 13,
                  fontWeight: 400,
                  textAlign: 'center',
                }}
              >
                {ingredient?.engName}
              </span>
            </div>
            <div style={{ width: 30 }} />
          </div>
          <div>
            {ingredient && (
              <div>
                <div className={styles.body}>
                  {!!(ingredient.diffName || ingredient.displayedDiffName) && (
                    <>
                      <div className={styles.typeLine}>
                        <span className={styles.titleStyle}>이명</span>
                      </div>
                      <div
                        className={styles.titleLine}
                        style={{
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {!!ingredient?.displayedDiffName && <span className={styles.remarksText}>{ingredient.displayedDiffName}</span>}
                        {!!ingredient.diffName && <span className={styles.remarksText}>{ingredient.diffName}</span>}
                      </div>
                    </>
                  )}

                  <div className={styles.typeLine}>
                    <span className={styles.titleStyle}>EWG 등급</span>
                    <span
                      className={styles.titleStyle + ' ' + styles.textButton}
                      onClick={() => {
                        //  navigation.push('IngredientRateGrades', {
                        //    productData: ingredient.product || ingredient,
                        //  });
                        navigate(`/ewgGrade?version=${version}`);
                      }}
                    >
                      EWG 등급 기준
                    </span>
                  </div>
                  <div className={styles.ewgLine}>
                    <img style={{ width: 50, height: 50, objectFit: 'contain' }} src={images[gradeIcon]} />
                    <div
                      style={{
                        marginLeft: 14,
                        height: 50,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 700,
                          color: '#898989',
                          fontSize: 12,
                          paddingTop: 5,
                        }}
                      >
                        {version === 'v1' ? ingredientGradeText[ewgGrade] : ingredientGradeText[cosGrade]}
                      </span>
                      {version === 'v1' ? null : <span className={styles.explainText}>{ingredientGradeText[gradeIcon]}</span>}
                    </div>
                  </div>
                  <div className={styles.typeLine}>
                    <span className={styles.titleStyle}>성분 설명</span>
                  </div>
                  <div
                    className={styles.titleLine}
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {!ingredient.remarks ? (
                      <div
                        style={{
                          alignItems: 'center',
                          borderBottomWidth: 0,
                          width: '100%',
                        }}
                      >
                        <span className={styles.grayStyle}>해당 없음</span>
                      </div>
                    ) : (
                      <span className={styles.remarksText}>{ingredient.remarks}</span>
                    )}
                  </div>
                  <div className={styles.typeLine}>
                    <span className={styles.titleStyle}>주의 성분</span>
                  </div>
                  <div className={styles.titleLine}>
                    {isEmptyCaution ? (
                      <span className={styles.grayStyle}>해당 없음</span>
                    ) : (
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {version === 'v1' && ingredient.isHarmness && (
                          <IngredientExplainItem imageStyle={styles.imageStyle} value={'isHarmness'} />
                        )}
                        {[momguideGrade, /*isDSL, isEPA ,*/ echaCMR, isSlsSles, isFragrance, isAmmonium, isHumid, isColor].map((value) => {
                          return ingredientExplainText[value] ? (
                            <IngredientExplainItem key={value} imageStyle={styles.imageStyle} value={value} />
                          ) : null;
                        })}

                        <div
                          style={{
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                          }}
                        >
                          {isSlsSles.color === '#ff7d7d' && (
                            <CautionCircleIcon iconSource={isSlsSles} data={getFilterData([ingredient], 'isSlsSles')} name="isSlsSles" />
                          )}
                          {isFragrance.color === '#ff7d7d' && (
                            <CautionCircleIcon
                              iconSource={isFragrance}
                              data={getFilterData([ingredient], 'isFragrance')}
                              name="isFragrance"
                            />
                          )}
                          {isAmmonium.color === '#ff7d7d' && (
                            <CautionCircleIcon iconSource={isAmmonium} data={getFilterData([ingredient], 'isAmmonium')} name="isAmmonium" />
                          )}
                          {isColor.color === '#ff7d7d' && (
                            <CautionCircleIcon iconSource={isColor} data={getFilterData([ingredient], 'isColor')} name="isColor" />
                          )}
                          {isHumid.color === '#ff7d7d' && (
                            <CautionCircleIcon iconSource={isHumid} data={getFilterData([ingredient], 'isHumid')} name="isHumid" />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.typeLine}>
                    <span className={styles.titleStyle}>성분 독성 정보</span>
                  </div>
                  <div className={styles.titleLine}>
                    {isEmptyToxicity && <span className={styles.grayStyle}>해당 없음</span>}

                    {ingredientExplainText[isAllergic] && <IngredientExplainItem imageStyle={styles.imageStyle} value={isAllergic} />}

                    {ingredient.echaBreath ? <IngredientExplainItem imageStyle={styles.imageStyle} value={'echaBreathRed'} /> : null}

                    {ingredient.echaSkin ? <IngredientExplainItem imageStyle={styles.imageStyle} value={'echaSkinRed'} /> : null}

                    {/* {ingredient.echaGrowth ? <IngredientExplainItem imageStyle={styles.imageStyle} value={'echaGrowthRed'} /> : null} */}
                  </div>
                  <div className={styles.typeLine}>
                    <span className={styles.titleStyle}>배합 용도</span>
                  </div>
                  <div className={styles.titleLine} style={{ paddingBottom: 20 }}>
                    {!ingredient.usePurpose ? (
                      <span className={styles.grayStyle}>해당 없음</span>
                    ) : (
                      <div style={{ width: '100%' }}>
                        <span className={styles.remarksText}>{ingredient.usePurpose}</span>
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <span
                      style={{
                        fontSize: 11,
                        color: '#898989',
                        width: '100%',
                      }}
                    >
                      ※성분 정보는 성분/원료 자체의 특성에 한하며, 함량, 노출 경로에 따라 달라질 수 있음.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </MobileTemplate>
    </AppContainerView>
  );
};

export default IngredientDetail;

export const Head = ({ location, params, data, pageContext }: any) => {
  // TODO: complete SEO
  // <title>{ingKorName} | 맘가이드</title>
  // <meta name="description" content={`${ingKorName}`} />
  // <link rel="canonical" href={`https://momguide.co.kr/ingredientDetail/${ingredientId}/?version=${version}`} />

  return <SEO title="성분 상세"></SEO>;
};
